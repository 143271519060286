<template>
  <div class="home">
    <div id="left"></div>
    <div id="screen" class="screen"></div>
    <el-dialog title="视频播放" width="50%" :visible.sync="dialogVideoVisible">
      <div style="position: relative; padding: 30% 45%;">
        <iframe
          style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;"
          :src="curPicUrl"
          scrolling="no"
          border="0"
          frameborder="no"
          framespacing="0"
          allowfullscreen="true"
        >
        </iframe>
      </div>
    </el-dialog>
    <el-dialog title="图片展示" width="50%" :visible.sync="dialogPicVisible">
      <div style="position: relative; padding: 30% 45%;">
        <iframe
          style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;"
          :src="curPicUrl"
          scrolling="no"
          border="0"
          frameborder="no"
          framespacing="0"
          allowfullscreen="true"
        >
        </iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ZThree from '@/three/ZThree.js';
import * as THREE from 'three';
import { loaderModel } from '@/three/loaderModel';
import TWEEN from 'three/examples/jsm/libs/tween.module.js';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { cssRender } from '@/three/cssRender';
import nipplejs from "nipplejs";

let app, camera, scene, renderer, controls, clock, movedistance = 0;
let playerforward = new THREE.Vector3();
let canmove = false;

/*let box = new THREE.BoxGeometry(1,1,1);
let normalmaterial = new THREE.MeshBasicMaterial( {color: 0x000000} ); 
let cube = new THREE.Mesh(box, normalmaterial);*/

export default {
  name: 'HomeView',
  components: {},
  data(){
    return{
      curPicUrl:null,
      dialogVideoVisible: false,
      dialogPicVisible: false
    };
  },
  methods:{
    async initZThree(){
      app = new ZThree('screen');
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.createLine();
      app.initLight();
    
      window.app = app
      camera = app.camera;
      const vec3Pos = new THREE.Vector3(0.63, 2.87, -23.18);
      camera.position.copy(vec3Pos);
      scene = app.scene;
      renderer = app.renderer;
      renderer.outputEncoding = THREE.sRGBEncoding;
      controls = app.controls;
      controls.target.copy(vec3Pos.add(new THREE.Vector3(0, 0, 0.1)));
      controls.enableZoom = false;
      controls.enablepan = false;
      controls.minDistance = 0;
      controls.maxDistance = 0.01;

      //移动方块  
      /*cube.position.set(0.63, 2.87, -20);
      playerforward.copy(cube.position);
      scene.add(cube);*/

      var geometry = new THREE.PlaneBufferGeometry( 50, 50, 32 );
      var material = new THREE.MeshLambertMaterial( {color: 0x333333, side: THREE.DoubleSide} );
      var plane = new THREE.Mesh( geometry, material );
      plane.rotation.x =  Math.PI/2;//旋转地面
      scene.add( plane );

      clock = new THREE.Clock();

      //摇杆
      /*let joystick = nipplejs.create({
      zone: document.getElementById('left'),
      mode: 'static',
      position: { left: '20%', top: '80%' },
      color: 'white',
      });
      let xx, yy = 0;
      joystick.on('start', function(evt, data){
        canmove = true;
        xx = data.position.x;
        //console.log(xx);
        yy = data.position.y;
        //console.log(yy);
      })
      .on('move', function(evt, data){
        canmove = true;
        var dd = data.distance;
        movedistance = dd;
        playerforward.set(
          xx - data.position.x,
          0,
          yy - data.position.y
        );
        //console.log(dd);
      })
      .on('end', function(evt, data){
        canmove = false;
        movedistance = 0;
      })*/

      await loaderModel(app);

      //天空盒
      app.scene.background = app.loaderSky('texture/sky_day/');

      //let instance = new cssRender(CSS3DRenderer, app);
      //app.cssRenderer = instance.cssRenderer;
      //app.instance = instance;

      app.render(()=>{
        const delta = clock.getDelta();

        controls.update(delta);
        renderer.render(scene,camera);
        //app.cssRenderer.render(scene, camera);

        if(canmove){
          var campos = new THREE.Vector3();
          campos.copy(camera.position);
          var playerpos = new THREE.Vector3();
          playerpos.copy(cube.position);
          var dir = new THREE.Vector3();
          dir = playerpos.sub(campos);
          console.log(dir)
          //run(delta);
        }
        TWEEN.update();
      })
    }
  },
  mounted(){
      //窗口-
      this.$EventBus.$on('changeDialogV', (obj) => {
        this.curPicUrl = obj.url;
        this.dialogVideoVisible = true;
        cubeshow();
      });

      this.$EventBus.$on('changeDialogP', (obj) => {
        this.curPicUrl = obj.url;
        this.dialogPicVisible = true;
      });
      
      //键盘监听
      window.addEventListener("keydown", onKeyDown, false);
      window.addEventListener("keyUp", onKeyUp, false);
      update();
      this.initZThree();
  }
}
//摇杆控制
function run(delta){
  const forward = new THREE.Vector3(0,0,0);
  forward.copy(playerforward);
  forward.normalize();
  forward.multiplyScalar(movedistance * 0.1 * delta);
  const p = new THREE.Vector3().copy(cube.position);
  p.add(forward);
  cube.position.copy(p);
  console.log(camera.position)
  //camera.position.copy(p);
  //controls.update();
}

//wasd
var moveW = false, moveA = false, moveS = false, moveD = false;
var onKeyDown = function (event){
    switch (event.keyCode) {
      case 87:
        moveW = true;
        break;
      case 65:
        moveA = true;
        break;  
      case 83:
        moveS = true;
        break;
      case 68:
        moveD = true;
        break;
    }
}
var onKeyUp = function (event){
    switch (event.keyCode) {
      case 87:
        moveW = false;
        break;
      case 65:
        moveA = false;
        break;  
      case 83:
        moveS = false;
        break;
      case 68:
        moveD = false;
        break;
    }
}
var update = function(){
    let clock = new THREE.Clock();
    let delta = clock.getDelta();
    if(moveW){
      camera.translateZ(-2*delta);
      console.log('W');
    } else
    if(moveA){
      camera.translateX(-2*delta);
    } else
    if(moveS){
      camera.translateZ(2*delta);
    } else
    if(moveD){
      camera.translateX(2*delta);
    }
}


</script>

<style lang="less">
.main {
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
}
.screen {
  position:fixed;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0;
  left: 0;
}
</style>
