import * as THREE from 'three';

export const picData = {
    pic模型编辑器: {
        name:'pic模型编辑器',
        url: 'video/test1.mp4',
        type: 'other'
    },
    pic贵阳模型: {
        name:'pic贵阳模型',
        url: 'pic/图片1.png',
        type: 'pic'
    },
    pic智慧园区: {
        name:'pic智慧园区',
        url: '//player.bilibili.com/player.html?aid=759949922&bvid=BV1y64y1q757&cid=392402545&p=1',
        type: 'video'
    },
    pic车展系统: {
        name:'pic车展系统',
        url: '//player.bilibili.com/player.html?aid=759949922&bvid=BV1y64y1q757&cid=392402545&p=1',
        type: 'video'
    },
    pic贵阳json: {
        name:'pic贵阳json',
        url: '//player.bilibili.com/player.html?aid=759949922&bvid=BV1y64y1q757&cid=392402545&p=1',
        type: 'video'
    },
    pic云上城市: {
        name:'pic云上城市',
        url: '//player.bilibili.com/player.html?aid=759949922&bvid=BV1y64y1q757&cid=392402545&p=1',
        type: 'video'
    },
    pic拓扑图: {
        name:'pic拓扑图',
        url: 'pic/图片1.png',
        type: 'pic'
    }
}