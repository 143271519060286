import * as THREE from 'three';
import EventBus from '../bus';
import { loaderRoom } from './loaderRoom';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { CSS3DSprite } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { cssRender } from '@/three/cssRender';
import { ObjectLoader } from 'three';

export function loaderModel(app) {
    app.rayModel = [];
    app.helperBox = {};

    //生成标签的组
    app.controlGroup = new THREE.Group();
    app.scene.add(app.controlGroup);

    const oaNames = ['地板', '墙', '天窗栅栏', '房顶'];
    const rayModelNames = ['地板', '墙', '沙发', '中间屏'];
    

    return new Promise((resolve) => {
        const urls = [
            {
                type: 'rgbe',
                url: 'texture/royal_esplanade_1k.hdr',
                onLoad: (texture) => {
                    texture.mapping = THREE.EquirectangularReflectionMapping;
                     app.envMap = texture;
                }
            },
            {
                type: 'glb',
                url: 'model/model.glb',
                onLoad:(object) => {
                    console.log(object);
                    app.model = object.scene;
                }
            }
        ];


        let urlsLength = urls.length;
        app.iterateLoad(
            urls,  
            (xhr) => {
                let proportion = parseInt((xhr.loaded/xhr.total) * 100);
                if (proportion === 100){
                    EventBus.$emit('changeLoading', parseInt(100 / urlsLength));
                    urlsLength--;
                    if (urlsLength <= 1){
                        EventBus.$emit('changeScene', true);
                    }
                }
            },
            () => {
                app.model.traverse((obj) => {
                    if (obj.isGroup) {
                        if (rayModelNames.includes(obj.name)) {
                            obj.children.forEach((item) => {
                            app.rayModel.push(item);
                          });
                        }
                      }

                    if(obj.isMesh){
                        //if(obj.name === '房顶') {
                        //    obj.visible = false;
                        //}
                     
                        if (oaNames.includes(obj.name)){
                            const basicMaterial = new THREE.MeshBasicMaterial();
                            basicMaterial.map = obj.material.map;
                            basicMaterial.color = obj.material.color;
                            obj.material = basicMaterial;
                        } else {
                            obj.material.envMap = app.envMap;
                        }

                        
                        if(rayModelNames.includes(obj.name)){
                            app.rayModel.push(obj);
                        }


                        if(obj.name.indexOf('pic') > -1)
                        {
                            app.rayModel.push(obj);
                            const position = app.getModelWorldPostion(obj);
                            console.log(position);
                            let sprite = new THREE.Sprite(new THREE.SpriteMaterial({
                                map: new THREE.TextureLoader().load("pic/logo.png"),
                                sizeAttenuation: true // false的话 不随着相机拉远而变小
                            }))
                            let x = 0;
                            let z = 0;
                            x = position.x > 0 ? -0.1: 0.1;
                            z = position.z > 0 ? -0.1: 0.1;
                            //精灵图添加的位置
                            sprite.position.set(position.x + x, position.y, position.z + z);

                            app.scene.add(sprite);
                        }
                   
                        if(obj.name.indexOf('helperBox') > -1){
                            obj.geometry.computeBoundingBox();
                            const box = new THREE.Box3().setFromObject(obj);
                            const position = app.getModelWorldPostion(obj);
                            const Sposition = app.getModelWorldPostion(obj);
                            const childrenPosition = app.getModelWorldPostion(obj.children[0]);
 
                            const subVec3 = childrenPosition.clone().sub(position).normalize();

                            let x = 0;
                            let z = 0;
                            if(Math.abs(subVec3.x) > Math.abs(subVec3.z)){
                                x = subVec3.x > 0 ? 0.1: -0.1;
                            } else {
                                z = subVec3.z > 0 ? 0.1: -0.1;
                            }  
                            const number = 100;
                            position.x += -number *x;
                            position.z += -number *z;
                            
                            app.helperBox[obj.name] = {
                                model: obj,
                                box,
                                position: [position.x ,childrenPosition.y, position.z],
                                controls: [position.x + x,childrenPosition.y, position.z + z]
                            }

                            const Sbox = new THREE.BoxGeometry(1,1,1);
                            const material = new THREE.MeshBasicMaterial( {color: 0x000000} ); 
                            const cube = new THREE.Mesh( Sbox, material );
                            cube.position.set(Sposition.x, Sposition.y + 1.5, Sposition.z);
                            //app.scene.add(cube);

                            const helper = new THREE.Box3Helper(box, 0xff0000);
                            helper.visible = false;
                            //app.scene.add(helper);
                        }
                    }
                });

                /*for (const key in app.helperBox){
                    const obj = app.helperBox[key];
                    app.getModelWorldPostion(obj);
                    const position = app.getModelWorldPostion(obj);;
                    const box = new THREE.BoxGeometry(1,1,1);
                    const material = new THREE.MeshBasicMaterial( {color: 0x000000} ); 
                    const cube = new THREE.Mesh( box, material );
                    const worldPosition = new THREE.Vector3(); 
                    cube.position.set(0.63, 2.87, -23.18);
                    //cube.position.set(position.x, position.y, position.z);
                    console.log(app.getModelWorldPostion(cube));
                    app.scene.add(cube);

                    const html = `<div class="carColor-3d animated fadeIn" id="${obj.name}" position="${position}" ><p class="text">${key}</p></div>`;
                    app.instance = {
                        parent: app.controlGroup,
                        cssObject: CSS3DSprite,
                        name: obj.name,
                        element: html,
                        position,
                        scale:[1, 1, 1]
                    };
                }*/
                app.scene.add(app.model);
                loaderRoom(app);
                resolve();
            }
        );
    });
}
