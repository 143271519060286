import * as THREE from 'three';
import EventBus from '../bus';
import { picData } from '@/assets/data';

export function loaderRoom(app) {
  createIconGroup(app);
  //鼠标移动
  app.initRaycaster(onMouseMove, app.rayModel, 'mousemove');

  //鼠标点击
  app.initRaycaster(onClick, app.rayModel, 'click');

}

function onClick(selectObj, app) {
    if(app.controls.isClickLock) {
        return;
    }

    if (selectObj?.object?.name === '地板'){
        const point = selectObj.point;
        console.log(point);
        const len = point.clone().sub(app.camera.position).length();
        let position,
        controls,
        isContains = false;
        for (const key in app.helperBox) {
            if (Object.hasOwnProperty.call(app.helperBox, key)) {
                const obj = app.helperBox[key];
                if(obj.box.containsPoint(point)){
                    if(len <= 20){
                    isContains = true;
                    position = obj.position;
                    controls = obj.controls;
                    } else {
                        const subVec3 = app.camera.position.clone().sub(app.controls.target.clone());
                        position = [point.x, app.camera.position.y, point.z];
                        controls = [point.x - subVec3.x, app.camera.position.y - subVec3.y, point.z - subVec3.z];
                    }
                }                
            }
        }
        
        if(!isContains){
            const subVec3 = app.camera.position.clone().sub(app.controls.target.clone());
            position = [point.x, app.camera.position.y, point.z];
            controls = [point.x - subVec3.x, app.camera.position.y - subVec3.y, point.z - subVec3.z];
        }

        app.flyTo({ 
            position,
            controls,
            done: () =>{
                app.iconGroup.visible = true;
            },
            start: () => {
                app.iconGroup.visible = false;
            }
        });
    }
    if (selectObj?.object?.name.indexOf('pic') > -1) {
        const model = selectObj.object;
        console.log(model);
        const obj = picData[model.name];
        if (obj) {
            createCube(app);
             if(obj.type === 'video'){
                EventBus.$emit('changeDialogV', obj);
             } 
             else if(obj.type === 'pic'){
                 EventBus.$emit('changeDialogP', obj);
             }
        }
    }
}

function onMouseMove(selectObj, app){
    if (selectObj?.object?.name === '地板'){
        const point = selectObj.point;
        app.iconGroup.position.copy(point.add(new THREE.Vector3(0, 0.01, 0)));
        app.iconGroup.visible = true;
    } else {
        app.iconGroup.visible = false;
    }
}

function createIconGroup(app) {
  const group = new THREE.Group();

  const circleGeometry = new THREE.CircleGeometry(0.2, 32);
  const circleMaterial = new THREE.MeshBasicMaterial({
    color: '#fff',
    transparent: true,
    opacity: 0.2,
  });
  const circle = new THREE.Mesh(circleGeometry, circleMaterial);
  circle.rotateX(-0.5 * Math.PI);
  group.add(circle);

  const ringGeometry = new THREE.RingGeometry(0.22, 0.2, 32);
  const ringMaterial = new THREE.MeshBasicMaterial({
    color: '#fff',
    transparent: true
    ,
    opacity: 0.8,
    side: THREE.DoubleSide
  });
  const ring = new THREE.Mesh(ringGeometry, ringMaterial);
  ring.rotateX(-0.5 * Math.PI);
  group.add(ring);

  group.position.set(0.63, 1, -18.18);
  group.visible = false;0
  app.iconGroup = group;
  app.scene.add(group);
}

function createCube(app){
    var box = new THREE.BoxGeometry(8, 3, 0.2);
 
    const video = document.createElement('video');
    video.src = 'video/test1.mp4';
    video.crossOrigin = 'anonymous';
    video.loop = false;
    video.muted = true;
    
    video.play();
    const texture = new THREE.VideoTexture(video);
    const materialArray = [];
    materialArray.push(new THREE.MeshBasicMaterial({color: 0x0051ba}));
    materialArray.push(new THREE.MeshBasicMaterial({color: 0x0051ba}));
    materialArray.push(new THREE.MeshBasicMaterial({color: 0x0051ba}));
    materialArray.push(new THREE.MeshBasicMaterial({color: 0x0051ba}));
    materialArray.push(new THREE.MeshBasicMaterial({color: 0x0051ba}));
    materialArray.push(new THREE.MeshBasicMaterial({map: texture}));

    const cube = new THREE.Mesh( box, materialArray );
    cube.position.set(-35, 2.46, -15);
    cube.rotateY( -Math.PI / 2);
    app.scene.add(cube);

    video.addEventListener("ended", function(){
        app.scene.remove(cube);
    });
}